module.exports = {
  siteTitle: 'Hi! I\'m Kevin!',
  subTitle: 'Full Stack Developer',
  siteDescription: `About Me - Kevin Coleman`,
  keyWords: ['gatsbyjs', 'react', 'curriculum'],
  authorName: 'Kevin Coleman',
  twitterUsername: '_',
  githubUsername: 'csdeveloperkc',
  authorAvatar: '/images/avatar.png',
  authorDescription: `Full Stack Developer, with many years experience.I love what I do. <br/>
    <b>Front-end Development,</b>
    I use <strong>HTML,CSS,Javascript,JQuery, and React</strong> to architect and develop websites
    and applications. I stay constantly aware of how the field is developing.<br/><br/>
    <b>Back-end Development,</b>Using server-side languages like <strong>PHP, Perl</strong> and Database 
    like <strong>MYSQL and MariaDB</strong>, I handle the ‘behind-thescenes’ functionality of web applications.`,
  education: `<b>Bachelor of Science &#124; June 2004 &#124; University of California, Riverside</b><br/>
    <b>Major:</b> Computer Science<br/>
    <b>Related coursework:</b> UNIX System Administration, Principles of Programming Languages, Computer Networks, Intermediate Data Structures and Algorithms, Machine Organization And Assembly Language Programming, Compiler Design, Software Testing and Verification, Database Management Systems 
    `,
  skills: [
    {
      name: 'HTML',
      level: 80
    },
    {
      name: 'CSS',
      level: 75
    },
    {
      name: 'Javascript',
      level: 65
    },
    {
      name: 'NodeJs',
      level: 40
    },
    {
      name: 'React',
      level: 60
    },
    {
      name: 'Git',
      level: 70
    },
    {
        name: 'PHP',
        level: 70
    },
    {
          name: 'PERL',
          level: 90
    },
    {
          name: 'MYSQL',
          level: 80
    },
    {
          name: 'PYTHON',
          level: 65
    },
    /* more skills here */
  ],
  jobs: [
      /* more jobs here */
    {
       company: "City of Hesperia",
          begin: {
              month: 'Jan',
              year: '2021'
          },
          duration: null,
          occupation: "Information Systems Specialist",
          description: "Administration of the City’s software applications, related databases, reporting, monitoring performance, and assisting with determining staff technology needs.Activities related to software implementation, patching, coordinating testing, maintaining secure application environments."
    },
    {
      company: "City of Hesperia",
      begin: {
        month: 'Feb',
        year: '2020'
      },
      duration: '1 year & 10 months',
      occupation: "Information Technology Technician",
          description: "I provide technical assistance,training, and support in the use of IT hardware and software.My administrative duties to include work order response, routing and maintaining the City’s technology asset inventory."
  
     },
      {
      company: "VOICEGENIX LLC / MOBILIZE.AI",
      begin: {
        month: 'Apr',
        year: '2005'
      },
      duration: '14 yr & 2 months',
      occupation: "Fullstack developer",
      description: "Full stack developer. Used industry leading technologies like HTML, jQuery, Javascript, PERL, PHP, PYTHON,develop front- end web applications and websites.Also served as a System Administrator for over 30 enterprise level servers. The servers were located and managed remotely from a Colocation Datacenter."
    }, {
      company: "Alorica",
      begin: {
        month: 'Aug',
        year: '2004'
      },
      duration: '8 months',
      occupation: "PC Technician",
      description: "Worked as a PC Technician. We were a third-party contracted repair site for such big name PC manufacturers as Gateway, and DELL.My job as a technician was to physically access the pc, diagnose any issues, request any needed parts for repair, and replace needed parts."
  
    },
  ],
  portifolio: [
    {
      image: "/images/portfolio_crm.png",
      description: "CRM Web app",
      url: "https://secure.mobilize.ai/cgi-bin/newvt.pl?merchant=1000&user=demo&password=test"
    },
    {
      image: "/images/portfolio_mobilize.png",
      description: "Mobilize.ai website",
      url: "https://mobilize.ai"
    },
  /* more portifolio items here */
    {
          image: "/images/portfolio_safe_harbor.png",
          description: "Safe Harbor Website",
          url: "https://safeharborcci.org/"
    },
  ],
  social: {
    linkedin: "https://www.linkedin.com/in/kevin-coleman-5a474318a",
    github: "https://github.com/csdeveloperkc",
    email: "csdeveloperkc@gmail.com"
  },
    siteUrl: 'https://delightful-mud-0edced21e.azurestaticapps.net/',
  pathPrefix: '/gatsby-starter-cv', // Note: it must *not* have a trailing slash.
  siteCover: '/images/cover-blur.png',
    googleAnalyticsId: 'UA-172911018-1',
  background_color: '#ffffff',
  theme_color: '#25303B',
  fontColor: "#000000cc",
  enableDarkmode: true, // If true, enables dark mode switch
  display: 'minimal-ui',
  icon: 'src/assets/gatsby-icon.png',
  headerLinks: [
    {
      label: 'Home',
      url: '/',
    },
    {
      label: 'Portifolio',
      url: '/portifolio',
    },
    {
      label: 'Contact',
      url: '/contact'
    },
    {
      label: 'Privacy',
      url: '/privacy',
    },
    {
      label: 'Blog',
      url: 'https://csdeveloperkc-blog-gatsbycloud.vercel.app/'
    }
  ]
}